import Vue from "vue";
import VueRouter from "vue-router";

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     redirect: '/home',
    // },
    // 登录
    {
        path: "/accountLogin",
        component: () =>
            import("@/views/login/accountLogin")
    },
    // 忘记密码
    {
        path: "/forgetPassword",
        component: () =>
            import("@/views/login/forgetPassword")
    },
    // 绑定账号
    {
        path: "/bindingAccount",
        component: () =>
            import("@/views/login/bindingAccount")
    },
    // 注册
    {
        path: "/registerAccount",
        component: () =>
            import("@/views/login/registerAccount")
    },



    // 首页
    // {
    //     path: "/detail",
    //     component: () =>
    //         import ("@/views/detail/detail")
    // },
    {
        path: "/",
        redirect: '/home',
        component: () =>
            import("@/views/home/homePage"),
        // 嵌套路由
        children: [
            {
                // 首页
                path: '',
                component: () =>
                    import("@/views/home/components/home"),

            },
            {
                // 搜索结果
                path: '/searchResult',
                component: () =>
                    import("@/views/home/components/searchResult")
            },
            {
                // 新搜索结果
                path: '/newSearchResult',
                component: () =>
                    import("@/views/home/components/newSearchResult")
            },
            {
                // 分类结果
                path: '/typeResult',
                component: () =>
                    import("@/views/home/components/typeResult"),

            },
            {
                // 热门推荐
                path: '/hotRecommand',
                component: () =>
                    import("@/views/home/components/hotRecommand"),

            },
            {
                // 热门推荐详情
                path: '/detail',
                component: () =>
                    import("@/views/home/components/detail")
            },
            // {
            //     path: "",
            //     redirect: '/home/home',
            // },
            {
                // 发布
                path: '/release',
                component: () =>
                    import("@/views/release/release")
            },
            {
                // 普通分类页
                path: '/commonClassify',
                // name:'realtyClassify',
                component: () =>
                    import("@/views/home/components/commonClassify")
            },
            {
                // 普通分类二级页面
                path: '/classify_leve2',
                // name:'realtyClassify',
                component: () =>
                    import("@/views/home/classify/classify_leve2")
            },

            {
                // 普通分类详情页面
                path: '/details',
                component: () =>
                    import("@/views/home/classify/details")
            },

            {
                // 经纪人分类页
                path: '/brokerClassify',
                component: () =>
                    import("@/views/home/components/brokerClassify")
            },
            {
                // 经纪人分类二级页面
                path: '/broker_classify_leve2',
                component: () =>
                    import("@/views/home/classify/broker_classify_leve2")
            },
            {
                // 经纪人详情页面
                path: '/borker_details',
                component: () =>
                    import("@/views/home/classify/borker_details")
            },

            
            {
                // 房地产分类页
                path: '/houseClassify',
                component: () =>
                    import("@/views/home/components/houseClassify")
            },


            {
                // 新楼盘分类页
                path: '/realtyClassify',
                component: () =>
                    import("@/views/home/components/realtyClassify")
            },

            {
                // 房地产分类二级页面
                path: '/house_classify_leve2',
                component: () =>
                    import("@/views/home/classify/house_classify_leve2"),

            },

            {
                // 房地产分类详情页面
                path: '/particulars',
                component: () =>
                    import("@/views/home/classify/particulars"),

            },

            {
                // 汽车分类页 
                path: '/carClassify',
                component: () =>
                    import("@/views/home/components/carClassify")
            },

            {
                // 汽车分类二级页面
                path: '/car_classify_leve2',
                component: () =>
                    import("@/views/home/classify/car_classify_leve2"),

            },

            // 个人中心
            {
                path: '/person',
                component: () =>
                    import("@/views/person/person"),
                children: [{
                    path: "",
                    redirect: '/personInfor',
                },
                {
                    // 个人信息
                    path: '/personInfor',
                    component: () =>
                        import("@/views/person/center/personInfor")
                },
                {
                    // 个人信息
                    path: '/verifyPage',
                    component: () =>
                        import("@/views/person/center/verifyPage")
                },
                {
                    //我的消息
                    path: '/myMessage',
                    component: () =>
                        import("@/views/person/center/myMessage")
                },
                {
                    //平台————我的消息
                    path: '/myMessagePlatform',
                    component: () =>
                        import("@/views/person/center/myMessagePlatform")
                },
                {
                    //系统————我的消息
                    path: '/myMessageSystem',
                    component: () =>
                        import("@/views/person/center/myMessageSystem")
                },
                {
                    //会员中心
                    path: '/vip',
                    component: () =>
                        import("@/views/person/center/vip")
                },
                {
                    //我的发布
                    path: '/myRelease',
                    component: () =>
                        import("@/views/person/center/myRelease")
                },
                {
                    //申请广告
                    path: '/applyAd',
                    component: () =>
                        import("@/views/person/center/myAd/applyAd")
                },
                {
                    //编辑广告
                    path: '/resubmitApplyAd',
                    component: () =>
                        import("@/views/person/center/myAd/resubmitApplyAd")
                },
                {
                    //我的广告
                    path: '/myAd',
                    component: () =>
                        import("@/views/person/center/myAd/myAd")
                },
                {
                    //广告详情
                    path: '/adDetail',
                    component: () =>
                        import("@/views/person/center/myAd/adDetail")
                },


                //我的收藏
                {
                    path: '/collect',
                    component: () =>
                        import("@/views/person/center/collect")
                },
                // 人才招聘
                {
                    path: '/talentRecruitment',
                    component: () =>
                        import("@/views/person/talentRecruitment")
                },
                // 帮助中心
                {
                    path: '/helpCenter',
                    component: () =>
                        import("@/views/person/center/helpCenter")
                },
                // 信息订单
                {
                    path: '/inforOrder',
                    component: () =>
                        import("@/views/person/center/order/inforOrder")
                },
                // 信息详情订单
                {
                    path: '/inforOrderDetail',
                    component: () =>
                        import("@/views/person/center/order/inforOrderDetail")
                },
                // 广告订单
                {
                    path: '/adOrder',
                    component: () =>
                        import("@/views/person/center/order/adOrder")
                },
                // 广告详情订单
                {
                    path: '/adOrderDetail',
                    component: () =>
                        import("@/views/person/center/order/adOrderDetail")
                },
                // 会员订单
                {
                    path: '/vipOrder',
                    component: () =>
                        import("@/views/person/center/order/vipOrder")
                },
                // 会员详情订单
                {
                    path: '/vipOrderDetail',
                    component: () =>
                        import("@/views/person/center/order/vipOrderDetail")
                },

                // 修改密码(手机号)
                {
                    path: '/editPassword1',
                    component: () =>
                        import("@/views/person/set/editPassword1")
                },
                // 修改密码(邮箱)
                {
                    path: '/editPassword2',
                    component: () =>
                        import("@/views/person/set/editPassword2")
                },
                // 确认修改绑定手机号
                {
                    path: '/editBindingPhone',
                    component: () =>
                        import("@/views/person/set/editBindingPhone")
                },
                // 修改绑定手机号
                {
                    path: '/editBindingPhone2',
                    component: () =>
                        import("@/views/person/set/editBindingPhone2")
                },
                // 确认修改绑定邮箱
                {
                    path: '/editBindingEmail',
                    component: () =>
                        import("@/views/person/set/editBindingEmail")
                },
                // 修改绑定邮箱
                {
                    path: '/editBindingEmail2',
                    component: () =>
                        import("@/views/person/set/editBindingEmail2")
                },
                // 绑定邮箱
                {
                    path: '/bindingEmail',
                    component: () =>
                        import("@/views/person/set/bindingEmail")
                },
                // 绑定手机号
                {
                    path: '/bindingPhone',
                    component: () =>
                        import("@/views/person/set/bindingPhone")
                },
                // 意见反馈
                {
                    path: '/adviceFeedback',
                    component: () =>
                        import("@/views/person/set/adviceFeedback")
                },
                // 历史反馈
                {
                    path: '/adviceFeedback',
                    component: () =>
                        import("@/views/person/set/adviceFeedback")
                },
                // 反馈记录
                {
                    path: '/feedbackRecord',
                    component: () =>
                        import("@/views/person/set/feedbackRecord")
                },
                // 关于我们
                {
                    path: '/aboutUs',
                    component: () =>
                        import("@/views/person/aboutUs")
                },

                ]
            },


            {
                //联系我们
                path: '/contactUs',
                component: () =>
                    import("@/views/person/contactUs")
            }, {
                //重新发布
                path: '/resubmitRelease',
                component: () =>
                    import("@/views/person/resubmitRelease")
            },
            //
            {
                //发布审核详情
                path: '/applyReleaseDetail',
                component: () =>
                    import("@/views/person/applyReleaseDetail")
            },
        ]
    },
]

var router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router;