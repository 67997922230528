import axios from 'axios'
import errorCode from './errorCode.js'
import {
	baseURL
} from './config';
import store from '@/store/index.js';
import {
	Message
} from 'element-ui';

import router from '@/router/index.js'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: baseURL,
	// 超时
	// timeout: 10000
})
// request拦截器，用于加token
service.interceptors.request.use(config => {
	if (localStorage.getItem("userinfo")) {
		let token = JSON.parse(localStorage.getItem("userinfo")).user_token;
		config.headers.common['user-token'] = token;
	}
	config.headers.common['lang'] = localStorage.getItem("lang") == 'zh' ? 'zh' : 'sp';


	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
	// 未设置状态码则默认成功状态
	const code = res.data.code || 200;
	const status = res.status;
	// 获取错误信息
	const msg = errorCode[code] || res.data.msg || errorCode['default'] || res.error || res.data.res;
	//获取错误提示
	const ress = res.data.res;
	if (code === '1') {
		return res.data
	} else if (code == 0) {
		return res.data
	} else if (code == 2 || code == -1 || code == -999 || code == -900) {
		return res.data;
	} else if (code == '-201') {
		// localStorage.removeItem("userinfo");
		// Message.error(res.data.msg);
		// router.push({
		// 	path: "/accountLogin"
		// });
		return Promise.reject(res.data.msg)

	} else {
		// localStorage.removeItem("userinfo");
		Message.error(res.data.msg);
		// router.push({
		// 	path: "/accountLogin"
		// });
		return Promise.reject(res.data.msg)
	}
},
	error => {
		console.log('err' + error);
		Message.error(error);
		// localStorage.removeItem("userinfo");
		return Promise.reject(error)
	}
)



export default service
