<template>
	<div class="type_fiter" v-show="$store.state.isShowFilter" @click="showModalClick">
		<div class="modal" @click.stop="">
			<div class="all_type">
				<div class="all_type_left">
					<div class="all_type_item" v-for="(item,index) in classifyList" :key="index"
						@click="titleClick(item)">
						<div class="type_img">
							<img :src="item.thumb">
						</div>
						<div class="type_title">{{item.name}}</div>
						<div class="front">
							<img src="../assets/home_icons/front.png">
						</div>
					</div>
				</div>
				<!-- <keep-alive> -->
				<div class="all_type_right">
                        <div class="type_item" v-for="(item2,index2) in curClassifyList" :key="index2" @click="toTypeResult(item2)">
							<div class="level2_img">
								<img :src="item2.thumb" alt="">
							</div>
						    <div class="level2_name">{{item2.name}}</div>
					    </div>
				</div>
				<!-- </keep-alive> -->
			</div>
			<div class="advertise">
				<div class="advertise_img" v-if="bannerArr1">
					<img :src="bannerArr1.thumb" @click="bannerArrChange(bannerArr1)">
				</div>
				<div class="advertise_img" v-if="bannerArr2">
					<img :src="bannerArr2.thumb" @click="bannerArrChange(bannerArr2)">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import bus from '../common/function/bus.js'
	export default {
		data() {
			return {
				classifyList: [],
				curClassifyList: [], //当前点击的分类数组
				bannerArr1: '', //
				bannerArr2: '', //
			}
		},
		mounted() {
			this.getCategoryList();
			this.getAds(1, 12);
			this.getAds(2, 13);

			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.getCategoryList();
				}
			})
		},
		methods: {
			// 轮播图切换事件
			bannerArrChange(item) {
				console.log(item);
				let ads_type = item.ads_type;
				let bind_information_id = item.bind_information_id;
				let href = item.href;
				if (ads_type == 1) {
					if (href) {
						window.open(href)
					}
				} else {
					this.$router.push({
						path: '/home/detail',
						query: {
							information_id: bind_information_id,
						}
					});
				}
			},
			// 获取轮播图
			getAds(type, typeid) {
				this.$http.getAds({
					typeid,
				}).then(res => {
					if (res.code == 1) {
						switch (type) {
							case 1:
								if (res.data.length > 0) {
									this.bannerArr1 = res.data[0];
								}
								break;
							case 2:
								if (res.data.length > 0) {
									this.bannerArr2 = res.data[0];
								}
								break;
						}
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 去分类结果页
			toTypeResult(val) {
				console.log("分类分类", val);
				// if(!this.$store.state.userinfo){
				//    this.$message.error(this.$t('other.noLoginPleaseToLogin'));
				//     setTimeout(() => {
				// 		this.$router.push({
				// 			path: "/accountLogin"
				// 		});
				// 	}, 1500)  
				//    return false;
				// }
				// this.$router.go(0);
				this.$router.push({
					path: '/typeResult',
					query: {
						pId: val.pid,
						pName: val.pid_info.name,
						bnmName: val.pid_info.bnm_name,
						zhName: val.pid_info.name,
						id:val.id,
						// pages:1,
						// cateId: val.id,
					}
				});
				this.$store.commit('changeShowFilter');
			},
			// 模态框点击
			showModalClick() {
				this.$store.commit('changeShowFilter');
			},
			getCategoryList() {
				console.log("ppp");
				this.$http.getCate({
					is_hot: 0,
				}).then(res => {
					if (res.code == 1) {
						console.log("获取分类");
						console.log(res.data);
						this.classifyList = res.data;
						this.curClassifyList = res.data[0].children;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			// 标题点击
			titleClick(item) {
				this.curClassifyList = item.children;
			},
		}
	}
</script>
<style scoped="" lang="less">
	.type_fiter {
		position: fixed;
		top: 150px;
		width: 100%;
		height: 932px;
		background-color: rgba(0, 0, 0, 0.5);
		overflow: hidden;
		display: flex;
		box-sizing: border-box;
		z-index: 100;
	}

	.modal {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		max-height: 600px;
		margin: 0 auto;
		background: #F6F7F9;
	}

	.all_type {
		display: flex;
		box-sizing: border-box;

		.all_type_left {
			height: 100%;
			overflow-y: scroll;

			padding: 0;
			background: #F6F7F9;
			background-color: #FFFFFF;
			display: inline-block;

			.all_type_item {
				box-sizing: border-box;
				width: 328px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 16px 40px;
				color: rgba(51, 51, 51, 1);

				.type_img {
					margin-right: 16px;
					display: flex;
					align-items: center;

					img {
						display: flex;
						align-items: center;
						width: 40px;
						height: 40px;
					}
				}

				.type_title {
					text-align: left;
					font-size: 14px;
					width: 160px;
					word-break: break-all;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.front {
					display: flex;
					align-items: center;
					// margin-left: 60px;

					img {
						width: 8px;
						height: 14px;
					}
				}
			}

			.all_type_item:hover {
				background: #F6F7F9;
				cursor: pointer;
				color: #F39800;
			}
		}

		.all_type_right {
			box-sizing: border-box;
			width: 608px;
			display: inline-block;
			background: #F6F7F9;
			padding: 32px 0 0 56px;
			text-align: left;

			.type_item {
				box-sizing: border-box;
				padding-right: 24px;
				margin-bottom: 36px;
				display: inline-block;
				text-align: center;
				word-break: break-all;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 16px;
				justify-content: center;

				.level2_img {
					display: flex;
					justify-content: center;

					img {
						border-radius: 8rpx;
						width: 55px;
						height: 55px;
					}
				}

				.level2_name {
					margin-top: 12px;
				}
			}

			.type_item:hover {
				color: #F39800;
				cursor: pointer;
			}
		}
	}

	.advertise {
		padding: 24px;

		.advertise_img {
			margin-bottom: 24px;

			img {
				width: 200px;
				height: 200px;
			}
		}
	}
</style>
