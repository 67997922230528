<template>
	<div id="app">
		<router-view/>

		<!-- <keep-alive>
            <router-view v-if='$route.meta.keepAlive'/>
        </keep-alive>
        <router-view v-if='!$route.meta.keepAlive'/> -->
		
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		data(){
			return{
				description: "",
                description2: "",
			}
		},
		metaInfo() {
			return {
				title: this.title,
				meta: [
					{ vmid: "description", name: "description", content: this.description },
					{
					vmid: "keywords",
					name: "keywords",
					content: this.description2,
					},
				],
			};
		},
		created() {
			this.initData();
		},
		methods:{
			initData(){
				this.description ='巴拿马';
				this.description2 ='巴拿马二测';
				// keyWord({
				// 	siteId: window.localStorage.getItem("siteId"),
				// 	}).then((res) => {
				// 		console.log(res,'快快快');
				// 		if (res.code = 200) {
				// 			this.title = res.data.index_title;
				// 			this.description = res.data.desc;
				// 			this.description2 = res.data.keys;
				// 		}
				// });
			}
		}

	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	.el-button--primary {
		background-color: #F39800 !important;
		border-color: transparent !important;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #F39800 !important;
		color: #fff;
	}

	.el-select-dropdown__list .el-select-dropdown__item.selected {
		color: #F39800 !important;
	}


	img {
		object-fit: cover;
	}

	/* 定义滚动条样式 */
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: rgba(240, 240, 240, 1);
	}

	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
		border-radius: 10px;
		background-color: rgba(240, 240, 240, .5);
	}

	/*定义滑块 内阴影+圆角*/

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
		background-color: rgba(240, 240, 240, .5);
		background-color: #999999;
		height: 30px;
	}



	.el-cascader-node.in-active-path,
	.el-cascader-node.is-active,
	.el-cascader-node.is-selectable.in-checked-path {
		color: #F39800 !important;
		font-weight: 700;
	}

	.el-date-table td.end-date span,
	.el-date-table td.start-date span {
		background-color: #F39800 !important;
	}

	.el-date-table td.today span {
		color: #F10200 !important;
	}

	.el-date-table td.current:not(.disabled) span {
		background-color: #F39800 !important;
	}

	.el-message-box {
		width: 580px !important;
		padding: 5px 20px 30px !important;
	}

	.el-radio__input.is-checked+.el-radio__label {
		color: #F39800 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
		background: #F39800 !important;
		border-color: #F39800 !important;
	}

	.el-carousel__button {
		width: 8px !important;
		height: 8px !important;
		border-radius: 50%;
	}
</style>
