import request from '@/common/utils/request'


// 获取验证码
export function getCode(data) {
    return request({
        url: 'v2/5b5bdc44796e8',
        method: 'post',
        data: data,
    })
}


// 获取区号
export function getAreaCode(data) {
    return request({
        url: 'v2/60a8addfd0872',
        method: 'post',
        data: data,
    })
}

// 注册
export function registerUser(data) {
    return request({
        url: 'v2/5cad9f63e4f94',
        method: 'post',
        data: data
    })
}

// 获取协议
export function getArticle(data) {
    return request({
        url: 'v2/5d63befcb25d9',
        method: 'post',
        data: data
    })
}


// 登录
export function accountLogin(data) {
    return request({
        url: 'v2/5c78dbfd977cf',
        method: 'post',
        data: data
    })
}

//忘记密码
export function forgetPassword(data) {
    return request({
        url: 'v2/5caeeba9866aa',
        method: 'post',
        data: data
    })
}

// 热门推荐
export function getRecommend(data) {
    return request({
        url: 'v2/61502bd0c2de2',
        method: 'post',
        data: data
    })
}



// 信息详情
export function informationDetail(data) {
    return request({
        url: 'v2/61528cb26fb09',
        method: 'post',
        data: data
    })
}

// 信息评论列表
export function commentInformationList(data) {
    return request({
        url: 'v2/6152bc18e7627',
        method: 'post',
        data: data
    })
}


// 举报
export function inform(data) {
    return request({
        url: 'v2/6152ca5b5aff4',
        method: 'post',
        data: data
    })
}



// 搜索结果
export function searchResult(data) {
    console.log(data);
    return request({
        url: 'v2/61513626d05f7',
        method: 'post',
        data: data
    })
}


// 分类信息
export function getCate(data) {
    return request({
        url: 'v2/6150233563a07',
        method: 'get',
        params: data
    })
}


// 城市信息
export function getCountry(data) {
    return request({
        url: 'v2/61503a88f31ab',
        method: 'post',
        data: data
    })
}

// 获取二级分类
export function getChildrenCate(data) {
    return request({
        url: 'v2/617b62f46944a',
        method: 'get',
        params: data
    })
}


// 获取信息类型
export function getCateType(data) {
    return request({
        url: 'v2/614d8fc7e7ae8',
        method: 'get',
        params: data
    })
}


// 发布信息
export function publishInformation(data) {
    return request({
        url: 'v2/614d8d4b1f30b',
        method: 'post',
        data: data
    })
}

// 编辑信息
export function editPublishInformation(data) {
    return request({
        url: 'v2/621c8b80d74a1',
        method: 'post',
        data: data
    })
}

// 消息类型
export function getMsgType(data) {
    return request({
        url: 'v2/5d67ac9454d53',
        method: 'post',
        data: data
    })
}

// 订单列表
export function msgList(data) {
    return request({
        url: 'v2/61526fa3ce688',
        method: 'post',
        data: data
    })
}


// 删除发布信息
export function deleteInformation(data) {
    return request({
        url: 'v2/615283487d013',
        method: 'get',
        params: data
    })
}


// 信息详情
export function editInformationDetail(data) {
    return request({
        url: 'v2/615278040e3bc',
        method: 'post',
        data: data
    })
}


// 收藏取消收藏
export function collectionInformation(data) {
    return request({
        url: 'v2/6152b01bd7b0a',
        method: 'post',
        data: data
    })
}

// 收藏列表
export function collectionList(data) {
    return request({
        url: 'v2/6152b303ecd74',
        method: 'post',
        data: data
    })
}

// 取消收藏（我的收藏列表）
export function cancel_collection(data) {
    return request({
        url: 'v2/6152b01bd7b0a',
        method: 'post',
        data: data
    })
}

// 个人中心
export function userInfo(data) {
    return request({
        url: 'v2/6163d91518ab4',
        method: 'get',
        params: data
    })
}


// 个人资料修改
export function edit_user_info(data) {
    return request({
        url: 'v2/5cb54af125f1c',
        method: 'post',
        data: data
    })
}


// 帮助中心
export function helpCenter(data) {
    return request({
        url: 'v2/5d648c8d37977',
        method: 'post',
        data: data
    })
}

// 帮助中心详情
export function helpCenterDetail(data) {
    return request({
        url: 'v2/5d64a46459991',
        method: 'post',
        data: data
    })
}


// 套餐
export function vipList(data) {
    return request({
        url: 'v2/615e630debf19',
        method: 'get',
        params: data
    })
}

// 会员权益
export function user_rights(data) {
    return request({
        url: 'v2/607ce1d7d47cb',
        method: 'post',
        data: data
    })
}

// 所有反馈记录
export function allRecord(data) {
    return request({
        url: 'v2/5d63ba953ee01',
        method: 'post',
        data: data
    })
}

// 意见反馈
export function addSuggestion(data) {
    return request({
        url: 'v2/5cc3f28296cf0',
        method: 'post',
        data: data
    })
}

// 意见反馈列表
export function suggestionsList(data) {
    return request({
        url: 'v2/5cb97ad30ea88',
        method: 'post',
        data: data
    })
}


// 关于我们
export function aboutUs(data) {
    return request({
        url: 'v2/60e561cd51d26',
        method: 'get',
        params: data
    })
}


// 校验验证码
export function verifyCode(data) {
    return request({
        url: 'v2/5f6db4db8abcf',
        method: 'post',
        data: data
    })
}

// 个人中心
// 修改密码
export function editPassword(data) {
    return request({
        url: 'v2/5da9ab4c4c7af',
        method: 'post',
        data: data
    })
}


// 修改绑定手机号
export function bindingPhone(data) {
    return request({
        url: 'v2/5f6c915d69d1f',
        method: 'post',
        data: data
    })
}

// 修改绑定邮箱
export function bindingEmail(data) {
    return request({
        url: 'v2/615e96e1bf87e',
        method: 'post',
        data: data
    })
}

// 获取未读消息
export function getUnread(data) {
    return request({
        url: 'v2/5ff58503a5ab7',
        method: 'post',
        data: data
    })
}


// 获取系统消息/站内信
export function getMsgList(data) {
    return request({
        url: 'v2/5cc56966e9287',
        method: 'get',
        params: data
    })
}

// 消息详情
export function getMsgDetail(data) {
    return request({
        url: 'v2/615124976338c',
        method: 'post',
        data: data
    })
}


// 首页轮播图
export function getAds(data) {
    return request({
        url: 'v2/5c94aa1a043e7',
        method: 'post',
        data: data
    })
}

// 	购买套餐生成订单
export function vipAddOrder(data) {
    return request({
        url: 'v2/6163d9bb5fb7d',
        method: 'post',
        data: data
    })
}


// 发起paypal支付
export function getPayPal(data) {
    return request({
        url: 'v2/6184ddd499e22',
        method: 'post',
        data: data
    })
}

// 发起paypal支付
export function getYaPay(data) {
    return request({
        url: 'v2/646b400bad167',
        method: 'post',
        data: data
    })
}

// 发起余额支付
export function getYuePay(data) {
    return request({
        url: 'v2/5e489f45a051d',
        method: 'post',
        data: data
    })
}


// 动态--发布评论
export function addComment(data) {
    return request({
        url: 'v2/6152bbbf0e09b',
        method: 'post',
        data: data
    })
}

// 获取微信登录的二维码PC端
export function wechat_qr_code(data) {
    return request({
        url: 'v2/61d682a486d3f',
        method: 'get',
        params: data
    })
}


// 是否已经第三方绑定
export function userSocialLogin(data) {
    return request({
        url: 'v2/5d7660a421e69',
        method: 'post',
        data: data
    })
}



// 绑定微信号
export function bindingAccount(data) {
    return request({
        url: 'v2/5d7757d28d076',
        method: 'post',
        data: data
    })
}


// 微信PC扫码登录获取用户信息
export function pc_wx_login(data) {
    return request({
        url: 'v2/61d662c2deb7f',
        method: 'post',
        data: data
    })
}


// 是否停用
export function isStopMessage(data) {
    return request({
        url: 'v2/61d7a3063a5c5',
        method: 'get',
        params: data
    })
}


// 	手机或邮箱认证
export function isVerify(data) {
    return request({
        url: 'v2/61dbc405b93bf',
        method: 'post',
        data: data
    })
}


// 	获取说明
export function getDesc(data) {
    return request({
        url: "v2/620ca0276e0bd",
        method: 'get',
        params: data
    })
}


// 广告位列表
export function get_ads_list(data) {
    return request({
        url: "v2/620cbd14a72f6",
        method: 'get',
        params: data
    })
}

// 	生成购买广告订单
export function add_ads_order(data) {
    return request({
        url: "v2/6205d8570dfc7",
        method: 'post',
        data: data
    });
}


// 	我的广告列表
export function myAdsList(data) {
    return request({
        url: "v2/620cb75f22c70",
        method: 'get',
        params: data
    });
}

// 	订单接口
export function orderList(data) {
    return request({
        url: "v2/620cbf960c2da",
        method: 'get',
        params: data
    });
}

// 取消订单
export function cancelOrder(data) {
    return request({
        url: "v2/620cc4f6cd0ee",
        method: 'post',
        data: data
    });
}


// 
export function workList(data) {
    return request({
        url: "v2/620c9816c3a24",
        method: 'get',
        params: data
    })
}

// 简历上传
export function addUserWork(data) {
    return request({
        url: "v2/620c98771ba60",
        method: 'post',
        data: data
    });
}


// 信息底色推荐价格	
export function getPrice(data) {
    return request({
        url: "v2/62199683f3a34",
        method: 'get',
        params: data
    });
}

// 订单详情	
export function orderDetail(data) {
    return request({
        url: "v2/6219d9bb4c4c4",
        method: 'get',
        params: data
    });
}


// 	删除订单
export function orderDel(data) {
    return request({
        url: "v2/6219ded82f8f4",
        method: 'post',
        data: data
    });
}

// 我的广告详情	
export function myAdsInfo(data) {
    return request({
        url: "v2/6219da42643db",
        method: 'get',
        params: data
    });
}

// 	修改广告	
export function operation(data) {
    return request({
        url: "v2/6219da8647a6f",
        method: 'post',
        data: data
    });
}

// 	编辑发布信息
export function editInformation(data) {
    return request({
        url: "v2/621c8b80d74a1",
        method: 'post',
        data: data
    });
}

// 选择支付方式
export function changePay(data) {
    return request({
        url: "v2/5fd081091c411",
        method: 'post',
        data: data
    });
}


// 公司信息提交
export function companyInfor(data) {
    return request({
        url: "v2/64660cfbc96ff",
        method: 'post',
        data
    });
}

// 获取公司信息 6466e33412c65
export function getcompanyInfor() {
    return request({
        url: "v2/6466e33412c65",
        method: 'post',
    });
}


// 获取首页全部分类 646b20fe06f9c
export function homeInfoList() {
    return request({
        url: "v2/646b20fe06f9c",
        method: 'post',
    });
}

// 获取代理类型 646ded65f3103
export function getType() {
    return request({
        url: "v2/646ded65f3103",
        method: 'post',
    });
}

// 搜索内容和一二级页面内容 646eb834d4848
export function getSearchInfo(data) {
    return request({
        url: "v2/646eb834d4848",
        method: 'post',
        data
    });
}

// 经纪人列表 6470222969ed2
export function getBorkerList(data) {
    return request({
        url: "v2/6470222969ed2",
        method: 'post',
        data
    });
}

// 经纪人详情
export function getBorkerInfo(data) {
    return request({
        url: "v2/64715b157aaef",
        method: 'post',
        data
    });
}


// 添加水印
export function addWatermark(data) {
    return request({
        url: "v2/6474048fcb6de",
        method: 'post',
        data
    });
}

// 一级分类轮播图 
export function getLbList(data) {
    return request({
        url: "v2/6150190418177",
        method: 'post',
        data
    });
}


// 谷歌登录
export function loginGoogle(data) {
    return request({
        url: "v2/6477eea91a865",
        method: 'post',
        data
    });
}


// facebook登录
export function loginFacebook(data) {
    return request({
        url: "v2/6465c9c75e490",
        method: 'post',
        data
    });
}

// 底部文章列表
export function bottomArticle(data) {
    return request({
        url: "v2/5d648c8d37977",
        method: 'post',
        data
    });
}


// 获取经销商
export function companyList(data) {
    return request({
        url: "v2/6487e5245f83d",
        method: 'post',
        data
    });
}

// 历史足迹 6488269e2f638	
export function historyList(data) {
    return request({
        url: "v2/6488269e2f638",
        method: 'post',
        data
    });
}


// 汽车品牌 	
export function carList(data) {
    return request({
        url: "v2/6497a51e3ec6c",
        method: 'post',
        data
    });
}


// 竞位价格 	
export function jwMoney(data) {
    return request({
        url: "v2/6497a5db354e4",
        method: 'post',
        data
    });
}


// 获取app下载二维码 	
export function erweima(data) {
    return request({
        url: "v2/6253a06741cd3",
        method: 'post',
        data
    });
}


// 获取注销原因	
export function zhuxiaoList(data) {
    return request({
        url: "v2/6152c90f9d647",
        method: 'post',
        data
    });
}

// 注销
export function zhuxiao(data) {
    return request({
        url: "v2/61035f64462d4",
        method: 'post',
        data
    });
}


