import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        createPersistedstate()
    ], //加上这个就可以了 //里面设置需要缓存的内容,
    state: {
        userinfo: '',
        isShowFilter: '',
    },
    mutations: {
        edituserinfo(state, data) {
            state.userinfo = data
        },
        changeShowFilter(state) {
            console.log(state.isShowFilter, '1111')
            state.isShowFilter = !state.isShowFilter;
        },
    },
})

export default store;