// const product_host = 'https://banama.njzb.vip'; // 测试环境域名  
// const product_host = 'https://shop.publichino.com'; // 测试三方登录的环境域名
const product_host = 'https://www.publichino.com'; //生产环境域名
// const product_host = 'https://testbnm.njzb.vip'; //崔通给的域名

let baseURL = `${product_host}/api`;
let uploadImgUrl = `${product_host}/api/v2/5d5fa8984f0c2`;
export {
    product_host,
    baseURL,
    uploadImgUrl,
}

