import Vue from 'vue';

const loadMore = Vue.directive('loadmore', {
	bind(el, binding) {
		// 获取element-ui定义好的scroll盒子
		const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
		SELECTWRAP_DOM.addEventListener('scroll', function() {
			console.log("俩就哈哈哈哈");
			console.log(this.scrollHeight);
			console.log(this.scrollTop);
			console.log(this.clientHeight);
			const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight + 6;
			console.log("当前的值", CONDITION);
			if (CONDITION) {
				binding.value()
			}
		})
	}
})

export {
	loadMore
}
