import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router/index';
import axios from 'axios';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import typeFilter from '@/components/typeFilter.vue';

import store from './store/index.js'

import less from 'less';
import * as http from '@/common/utils/http.js';


import VueI18n from 'vue-i18n';
import chinese from '@/lang/zh.js';
import spanish from '@/lang/sp.js';


import ElementLocale from 'element-ui/lib/locale'
import esLocale from 'element-ui/lib/locale/lang/es'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import tool from "@/common/utils/tool.js";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css';
import Clipboard from 'clipboard';
import Meta from 'vue-meta'





// 引入facebook登录插件
import FBSignInButton from 'vue-facebook-signin-button'
Vue.use(FBSignInButton)

// // 引入谷歌登录插件
import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton);







import {
    loadMore
} from '../directives.js';

import 'lib-flexible'

Vue.use(loadMore)
Vue.use(Meta)
// import Viewer from 'viewerjs';
// import 'viewerjs/dist/viewer.css';



Vue.prototype.Clipboard = Clipboard;

Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});




Vue.use(tool);

Vue.use(VueI18n);

Vue.use(less)

Vue.config.productionTip = false;

Vue.use(ElementUI);

// 全局注册组件
Vue.component('type-filter', typeFilter);

Vue.prototype.$axios = axios;
Vue.prototype.$http = http;



console.log("语言", localStorage.getItem("lang") == 'zh' ? 'zh' : 'sp');
const i18n = new VueI18n({
    locale: localStorage.getItem("lang") == 'zh' ? 'zh' : 'sp',
    messages: {
        zh: {
            ...chinese,
            ...zhLocale,
        },
        sp: {
            ...spanish,
            ...esLocale
        }
    }
});

Vue.prototype._i18n = i18n;

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



ElementLocale.i18n((key, value) => {
    return i18n.t(key, value);
})

// 跳转后返回顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})



new Vue({
    router,
    render: h => h(App),
    store,
    i18n,
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    }
}).$mount('#app')