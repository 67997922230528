//cn
export default {
    language: {
        selectLanguage: 'Seleccionar idioma',
        zh: '中文',
        sp: 'Español',
        confirm: 'Aceptar'
    },
    // 公共
    cancel: 'Cancelar',
    confirm: 'Aceptar',
    querenDel: 'Seguro eliminar todo/seleccionado',
    checkMore: 'Ver mas',
    delete: 'Eliminar',
    finish: 'Aceptar',
    nextStep: 'Siguiente',
    submit: 'Enviar',

    // 登录
    login: {
        // 登录
        phoneLogin: 'Iniciar sesión con Número de Celular',
        emailLogin: 'Iniciar sesión con Correo Electrónico',
        inputPhone: 'Ingrese su número de celular',
        inputEmail: 'Ingrese su Correo Electrónico',
        inputPassword: 'Ingrese su contraseña',

        login: 'Iniciar Sesión',
        registerAccount: 'Crear una cuenta',
        forgetPassword: '¿Olvidaste la contraseña?',
        quickLogin: 'Inicio de sesión rápido',
        // 注册
        phoneRegister: 'Registrarse con Número de Celular',
        emailRegister: 'Registrarse con Correo Electrónico',
        inputCode: 'Ingrese el código de verificación',
        // Ingrese el 
        getCode: 'Obtener código',
        sendAgain: 'Reenviar en',
        emailSendAgain: 'Vuelva a enviar en 10 minutos',
        regiser: 'Registrarse',
        existAccount: '¿Ya tienes una cuenta? ',
        tickRepresentsAgree: 'Aceptar',
        userRegistrationAgreement: 'Términos y condiciones',
        and: 'y',
        privacyPolicy: 'Política de privacidad',
        userAgreement: 'Acuerdo de registro de usuario',
        // 忘记密码C
        phoneFind: 'Número de Celular',
        emailFind: 'Correo Electrónico',
    },
    // 首页
    home: {
        address: 'Dirección',
        allType: 'Categorias',
        selectCity: 'seleccione su ciudad',
        city: 'La ciudad',
        price: 'precio',
        minPrice: 'El precio más bajo',
        maxPrice: 'Precio máximo disponible',
        clearChoose: 'Limpiar los criterios de filtro',
        gong: 'total',
        tiao: 'Barra de datos',
        pleaseEnterKeyword: '¿Qué desea encontrar?',
        collect: 'Guardar',
        hasCollect: 'Guardado',
        contact: 'Contactar',
        releaseTime: 'Publicado',
        pictureDisplay: 'Galería de Imágenes',
        projectSchedule: 'Etapa de Construcciónes',
        cartSchedule: 'Progreso de compra de vehículos',
        carShow: 'Exhibición de fuentes automotrices',
        priceNegotiation: 'Precio negociable',
        deal: 'El',
        drawing: 'Plano',
        InBuilding: 'En Construción',
        presale: 'Preventa',
        finish: 'Terminado',
        about: 'Descripción',
        specification: 'Especificaciones',
        benefit: 'Beneficios',
        model: 'Modelo',

        contactInformation: 'Información de Contacto',

        carspecification: 'Ficha Tecnica',



        detailDesc: 'Describir detallada',
        allComment: 'Comentarios',
        viewComments: 'Ver los comentarios',
        confirmToDeleteHistory: '¿Estás seguro de eliminar el historial?',
        noSearchRecordsExist: 'No hay registro de búsqueda',
        selectAddress: 'Seleccione región',
        selectType: 'Seleccione una categoría',
        price: 'Precio',
        reset: 'de nuevo',
        report: 'Reportar',
        reportContent: 'Reportar',
        uploadPhoto: 'Subir imágenes',
        provideMoreInformation: 'Proporcione información detallados',
        atMost: 'Hasta Máximo',
        open: 'fotos',
        informTips: 'Proporcionaremos comentarios dentro de las 24 horas posteriores a su informe, y luego se enviarán a su cuenta con un mensaje del sistema ',
        submit: 'Enviar',
        send: 'enviar',
        inputComment: 'Ingresar comentario',
        share: 'Compartir',
        share2: 'Compartir',
        commentText: 'No puede comentar su propia información publicada',
        search: 'Buscar',
        searchResult: 'Resultados de búsqueda',
        InformationClassification: 'Marketplace',
        newLouPan: 'Nuevos Proyectos',
        latestRelease: 'Anuncios recientes',
        realEstate: 'Bienes Raíces',
        shopCenter: 'Centro Comercial',
        car: 'Automóviles',
        newList: 'Anuncios Recientes',
        realEstateCompany: 'Agencias Inmobiliarias',
        usedCar: 'Autos usados',
        ultimosNewOnline: 'Anuncios Recientes',
        consecionaFranchisees: 'Consecionarios',
        hotBroker: 'Profesionales'



    },
    // 发布
    release: {
        title: 'Título',
        inputTitle: 'Introduzca un título',
        type: 'Categoría',
        selectType: 'Seleccione una categoría',
        cityLive: 'Ubicación',
        selectCity: 'Seleccione la ciudad',
        pleaseEnterDescriptionDetails: 'Por favor, introduzca una descripción detallada',
        pleaseSelect: 'Seleccione',
        pleaseInput: 'Ingrese',
        pleaseSelectRegion: 'Seleccione una región',
        pleaseInputInfo: 'Por favor complete la información',
        haveReadAndAgree: 'He leído y aceptado',
        PanamaAgreement: 'Acepto el acuerdo',
        immediatelyRelease: 'Publicar',
        submitSuccess: 'Publicación Enviada!',
        changeSuccess: 'Modificado con éxito',
        waitPatient: 'El tiempo de aprobación es 2-8 horas',
        backHomepage: 'Volver a la página de inicio',
        changeInfo: 'Guardar',
        placeholderYoushi: 'Comas inglesas separadas',
        total: 'Total a Pagar',
        JinWei: '¿Posicionar?'

    },
    // 我的
    my: {
        release: 'Mis publicaciones',
        openMembershipExpires: 'Inscribete y publica gratis',
        remainingNumber: 'Publicaciones disponibles',
        membershipDue: 'Su membresía expira',
        checkIn: 'En Aprobación',
        checkFailure: 'No Aprobado',
        checkSuccess: 'Aprobado',
        headPortrait: 'Foto perfil',
        Id: 'Identificación',
        nickName: 'Nombre',
        inputNickName: 'Escriba su nombre',
        sex: 'Género',
        private: 'Mantener oculto',
        birth: 'Fecha de nacimiento',
        save: 'Guardar',
        zhuxiao: 'ELIMINAR MI CUENTA',
        selectSex: 'Elige el género',
        selectBirth: 'Fecha de nacimiento',
        youHaveNoCurrentMembership: 'No has activado una membresía',
        validity: 'expiración',
        openMembershipToEnjoyMoreBenefits: 'Abre tu membresía y disfruta de más descuentos',
        memberFreeReleaseExclusivePrivileges: 'Publicación gratuit es exclusivo de miembros',
        exclusiveMembershipLogoRenewableExtension: 'Abre tu membresía y disfruta de todos los beneficios',
        ordinaryPackage: 'Promociones',
        vipPackage: 'Suscripción',
        vipSelect: 'Seleccione el paquete que más se adapte a sus necesidades',
        rightMember: 'Beneficios Miembros VIP',
        shopVip: 'Al presionar comprar declara que ha leído y acepta los',
        shopXieyi: 'Términos y Condiciones',
        buyNow: 'Comprar',
        immediatelyRenewal: 'Renovar',
        weiXinPay: 'Pago por WeChat',
        paypalPay: "Pago por PayPal",
        applePay: 'Pago por Apple Pay',
        payNow: 'Pagar',
        selectPayWay: 'Selecione método de pago',
        vipTips: 'Al comprar un paquete de Miembro VIP usted estará ahorrando',
        paySuccess: 'Pago exitoso',
        payWay: 'Método de pago',
        payTime: 'Tiempo de pago',
        checkMember: 'Ver Afiliación',
        applyIn: 'En Aprobación',
        expired: 'Ha expirado',
        applyFailure: 'No Aprobado',
        failureReason: 'Detalle de no aprobación',
        submitAgain: 'Reenviar',
        applySuccess: 'Aprobado',
        releaseTime: 'Publicado en',

        edit: 'Editar',
        finish: 'Finalizar',
        selectAll: 'Seleccionar todo',
        tips: '¡Alerta!',
        deleteAllContent: '¿Está seguro que desea eliminar las publicaciones seleccionadas de mis favoritos?',

    },
    // 设置
    set: {
        editPassword: 'Cambiar la contraseña',
        bindingPhone: 'Vincular número de celular móvil',
        editPhone: 'Gestionar Número de Celular',
        bindingEmail: 'Gestionar E-mail',
        editEmail: 'Cambio de email vinculado',
        languageSettings: 'Configuración de idioma',
        adviceFeedback: 'Reportar',
        CurrentMailboxNumber: 'Correo electrónico actual',
        Historical_feedback: 'Historial de Reportes',
        aboutUs: 'Nosotros',
        classifyText: 'Categorias',
        cancelAccount: 'Eliminar la cuenta',
        registrationProtocol: 'Términos y condiciones',
        privacyAgreement: 'Acuerdo de Privacidad',
        clearCache: 'Borrar cache',
        checkUpdates: 'Actualización de nueva versión',
        exitLogin: 'Salir',
        confirmClearCache: '¿Estás seguro de borrar el caché?',
        confirmExitLogin: '¿Estás seguro de cerrar sesión?',

        oldPasswordAuthentication: 'Cambio de contraseña',
        phoneAuthentication: 'Cambiar mi contraseña',
        emailAuthentication: 'Cambio de email',
        oldPassword: 'Contraseña anterior',
        inputOldPassword: 'Contraseña vieja',
        newPassword: 'Nueva contraseña',
        inputNewPassword: 'Contraseña nueva',
        confirmPassword: 'Confirmar Contraseña',
        inputConfirmPasswordAgain: 'Repita la nueva contraseña',
        wantToVerifyMobile: 'verificar su número de celular móvil vinculado',
        wantToVerifyEmail: 'verificar su correo electrónico vinculado ',

        newEmail: 'Nuevo Correo electrónico',
        inputNewEmail: 'correo eléctronico',
        newPhone: 'Nuevo número de celular',
        inputNewPhone: 'Ingrese su nuevo número de celular',
        smsVerification: 'Verificación por SMS',
        inputCode: 'código de confirmación',
        getCode: 'Obtener',
        second: 'Segundo',

        feedbackRecord: 'Registro',
        feedbackType: 'Tipo de Comentarios',
        feedbackContent: 'Contenido de comentarios',
        feedbackContentCannotEmpty: 'El contenido de los comentarios no puede estar vacío',
        feedbackContentTextarea: 'Detalle su Comentario',
        uploadPhoto: 'Sube capturas de pantalla de la aplicación, hasta 6 fotos',
        leaveYourContactInformation: 'Por favor deje su información de contacto',
        phoneAndQQAndEmail: 'Número de celular',
        contactWay: 'Información del contacto',
        // feedbackType: 'Tipo de Comentarios',
        feedbackTime: 'Tiempo de retroalimentación',
        platformReply: 'Respuesta de la plataforma',
        currentVersion: 'versión actual',
        confirmClearCache: '¿Estás seguro de borrar el caché?',
        exitLogin: 'Salir',
        fillInTheCancellationApplication: 'Complete la solicitud de cancelación ',
        accountCancel: 'Cancelar la cuenta ',
        permanentCancellation: 'cancelacion de la cuenta no se puede restaurar, opere con precaución ',
        selectCancelReason: 'Seleccione el motivo de la cancelación de su cuenta：',
        fillInTheCancelReason: "Indique el motivo de la cancelación",
        pleaseEnter: 'por favor escribe',
        accountCancelGiveUpRights: 'Una vez cancelada la cuenta, se renunciará a los siguientes derechos: ',
        cancellationInstructions: 'Detalles de cancelación ',
        clickTheButtonBelowToIndicateThatYouHaveReadIt: 'Haga clic en el botón de abajo, significa que ha leído y comprendido los riesgos anteriores: ',
        clearedTheDecksConfirmGoOn: 'He entendido los riesgos y estoy seguro de que seguiré ',
        back: 'anterior',
        pleaseClearedTheDecksConfirmGoOnAgain: 'Por favor confirme nuevamente que ha entendido el riesgo de cancelación y desea continuar realizando la operación ',
        cancellationApplicationSuccessful: 'Solicitud de cancelación enviada con éxito ',
        waitPatientlyForPlatformReview: ' Espere pacientemente la información de revisión de la plataforma, el tiempo de revisión estimado es de 1 a 3 días hábiles',
        beingProcessed: 'Procesando',
        cancellationReason: 'Razón de la cancelación',
        phoneText: 'Número de teléfono móvil incorrecto',
    },
    // 所有标题
    title: {
        forgetPassword: "Recuperar su contraseña",
        bindingAccout: 'Vincular cuenta ',
        systemMessages: 'información del sistema',
        platformNotice: 'Mensajes de plataforma ',
        messageDetail: 'Detalles del mensaje',
        systemDetail: 'Detalles del sistema ',

        classify: 'Categoría',
        detail: 'Detalles ',
        allComments: 'Todos los comentarios',
        hotRecommand: 'Recomendación popular ',

        myRelease: 'Mis Publicaciones',
        wantToRelease: 'Nueva Publicación',
        personCenter: 'Mi perfil',
        memberCenter: 'Comprar Paquetes',
        immediatePayment: 'Pagar Ahora',
        payResult: 'Resultado del pago ',
        myProfile: 'Mi perfil',
        myCollect: 'Favoritos',
        set: 'Configuración',
        editPassword: 'cambiar la contraseña',
        editBindingPhone: 'Cambiar el númede celular',
        editBindingEmail: 'Modificar correo electronico vinculado',
        iNeedFeedback: 'Mi Comentario',
        feedbackRecord: 'Registro de comentarios',
        aboutUs: 'sobre nosotros',
        cancelAccount: 'Cancelación la cuenta',
        registrationProtocol: 'Acuerdo de registro',
        privacyPolicy: 'Política de privacidad',
        helpCenter: 'Centro de ayuda',
        phoneService: 'servicio telefónico',
        advertiseDetail: 'Detalles del anuncio',
        inform: 'denunciar',
        orderDetail: 'Detalles del lanzamiento',
        personInfor: 'Información Personal',
        companyInfor: 'Información de Empresa',
    },
    // 所有message提示语
    tips: {
        mobilePhoneNumberCannotBeEmpty: 'Número de Celular no puede esta vacio',
        emailNumberCannotBeEmpty: 'El correo electrónico no puede estar vacío',
        codeCannotBeEmpty: 'se debe completar el código de verificación',
        passwordCannotBeEmpty: 'la contraseña no puede estar en blanco ',
        pleaseRightPassword: 'Ingresar (6-12 dígito, Número + Letra)',
        pleaseSelectUserProtocol: 'Verifique el acuerdo de usuario',
        onlyOneVideoCanBeUploaded: 'Solo se puede subir un video',
        pleaseEnterTitle: 'Por favor ingrese un título',
        pleaseEnterSelectionCategory: 'Introduzca la categoría de selección',
        pleaseSeleciCity: 'Por favor elija la ciudad',
        pleaseEnterDetailedDescription: 'Introduzca una descripción detallada',
        atLeastOnePhoto: 'Sube al menos una imagen',
        pleaseCheckThePanamaInformationReleaseAgreement: 'Consulte el Acuerdo de divulgación de información de Panamá ',
        cannotEmpty: 'no puede estar vacío',
        searchContent: 'El contenido de búsqueda no puede estar vacío',
        commentsCannotBeEmpty: 'El contenido del comentario no puede estar vacío',
        theReportContentCannotBeEmpty: 'El contenido del informe no puede estar vacío',
        photoCannotBeEmpty: 'La imagen no puede estar vacía',
        searchContentCannotBeEmpty: 'El contenido de búsqueda no puede estar vacío',
        pleaseCheckAtLeastOneItem: 'Marque al menos un elemento',
        theTypeCannotBeEmpty: 'El tipo no puede estar vacío',
        pleaseFillInTheReasonForCancellation: 'Indique el motivo de la cancelación ',

        // 需要翻译的
        validateUserEmail: 'Ingrese su correo eletrónico',
        oldPasswordCannotEmpty: 'La contraseña anterior no puede estar vacía',
        newPasswordCannotEmpty: 'La nueva contraseña no puede estar vacía',
        confirmPasswordCannotEmpty: 'confirmar la contraseña no puede estar en blanco',
        theTwoPasswordsAreInconsistent: 'Las dos contraseñas ingresadas son distinto',
        WeChatRegular: 'Introduzca la micro señal correcta',
    },


    other: {
        backResult: 'Página Anterior',
        share: 'Compartir',
        collect: 'La colección',
        report: 'denuncia',


        selectCity: 'Seleccione su ubicación',
        selectType: 'Elige un categoria',
        price: 'Precio',
        fromTheHigh: 'De mayor a menor',
        fromBottomToTop: 'De menor a mayor',
        wechatFriends: 'Amigos de WeChat',
        circleOfFriends: 'Compatir con los amigos',
        inform: 'reporte',
        myCollection: 'Mi colección',
        myMessage: 'Mensajes',
        set: 'Configuración',
        helpCenter: 'Centro de Ayuda',
        all: 'Todos',
        applyIn: 'En Aprobación',
        applyFailure: 'No Aprobado',
        applySuccess: 'Aprobado',
        uploadPhoto: 'SUBIR FOTO',
        uploadVideo: 'SUBIR VIDEO',
        bottom: 'Final',


        man: 'Masculino',
        women: 'Femenino',
        sendIn: 'enviando',
        regain: 'Readquirir',
        curVersion: 'versión actual',
        clearSuccess: 'Borrado con éxito',
        homePage: 'Inicio',
        release: 'Publicar Anuncio',
        my: 'Yo',
        revertText: 'Volver a la página anterior',

        noData: 'sin datos',
        textInOffset: 'Tire hacia abajo para refrescar',
        textOutOffset: 'Actualización de lanzamiento',
        textLoading: 'Cargando ...',
        textSuccess: 'Cargado con exito',
        textErr: ' puede que te interesa',


        guessLike: 'puede que te interesa',
        commonProblem: 'problema comun',

        rentHouse: 'Alquilar / compartir',

        // ------------未翻译的
        pleaseLogin: 'Por favor inicie sesión',
        toHome: 'Inicio',
        noLoginPleaseToLogin: 'Registrate para Publicar',
        confirmMakePhone: 'Confirmar la llamada',
        repeatText: 'No repita la presentación'
    },
    noData: 'Sin datos temporalmente',
    curPhoneNum: 'Su número actual es',
    curEmailNum: 'Correo electrónico actual',
    pleaseUploadPhoto: 'Sube hasta 6 fotos máximo',
    officialPhone: 'Celular',
    officialWx: 'Wechat',
    officialEmail: 'Buzón',
    officialWhatsAPP: 'WhatsApp',
    officialAddress: 'Dirección',
    search: 'Buscar',
    reset: 'Restablecer',
    releaseContent: 'Detalles',
    to: 'Hasta',
    submitTime: 'Buscar por fecha',
    submitTime2: 'Fecha de creación',
    flowText: 'Caudal', //新加的流量
    auditTime: 'Fecha de creación',
    startTime: 'Fecha de inicio',
    endTime: 'Fecha de finalización',
    state: 'Estado',
    reason: 'Causa',
    operation: 'Acciones',
    question: 'Contenido',
    checkDetail: 'Ver detalles',
    submitAgain: 'Volver a enviar',
    onlySee: 'Desactivar',
    shuiYin: 'Seleccione una Marca',
    chushou: 'Vendido',
    chuzu: 'Alquilado',
    allSee: 'Activar',

    expireTime: 'Fecha de Finalización',

    addWatermark: 'Marca de agua',
    basicInfo: ' Información básica',
    contactInfo: 'Información de contacto',
    detailAddress: 'Ubicación detallada',
    InputDetailAddress: 'Por favor, introduzca una ubicación detallada',
    message: 'Mensajes',
    deleteSelectList: 'Eliminar el contenido seleccionado',
    scanCodeToDownload: 'Escanear el código QR',
    uploadPhoto: 'Sube una foto',
    myAlsoSay: 'Quiero decir algo',
    curState: 'Estado', //El estado actual de la
    modifyReleaseInfo: 'Modificación de la información de publicación',
    download: 'App Movil',
    pleaseUploadTheCorrectVideoFormat: 'Por favor, sube el formato de video correcto',
    theSizeOfUploadedVideoCannotExceed: 'El tamaño del vídeo subido no debe superar los 10MB',
    pleaseUploadTheCorrectImageType: 'Por favor, sube el tipo de imagen correcto',

    // 增项
    publishingProtocol: 'Protocolo de publicación',
    releasePrice: 'El costo de la publicación es',
    totalPrice: 'Total a Pagar',

    infoEffectiveTime: 'Su publicación estará en circulación por',
    remainingTime: 'Tiempo restante disponible',

    weiXinContact: 'WeChat',
    messageContact: 'SMS',
    emailContact: 'Correo Electrónico',
    email: 'Buzón',
    inputEmail: 'Ingrese su Correo Electrónico',
    contactWXId: 'Cuenta de WeChat',
    copy: 'Copiar',
    contactEmailId: 'correo del contacto',
    inputSendContent: 'Escribir el mensajes',
    wechatID: 'WeChat',
    inputWechat: 'ID de WeChat',

    inputWhatsApp: 'Ingrese su número de Whatsapp',
    inputWechat: 'Ingrese su ID de WeChat',
    contacts: 'Persona de Contacto',
    inputContacts: 'Ingrese el nombre de la persona de contacto',
    contactsPhone: 'Teléfono de contacto',
    inputContactsPhone: 'Ingrese el número de Teléfono o Celular',
    day: 'dias',

    fullAddress: 'Dirección',
    telephone: 'Teléfono',
    mail: 'Correo Electrónico',
    WhatsApp: 'WhatsApp',
    wx: 'WeChat',
    T_phone: "Teléfono Local",
    InputT_phone: "Ingrese su Teléfono Local",
    E_mail: 'Correo Electrónico',
    InputE_mail: "Ingrese su Correo Electrónico",

    history: 'Vistos Recientemente',

    address: 'Dirección',
    companyName: 'Compañia',
    inputCompanyName: 'Nombre de la compañia',
    companyTaxNumber: 'Número de RUC',
    inputCompanyTaxNumber: 'Ingrese número de RUC y DV',
    brokerLicenseNumber: 'Número de Licencia',
    inputBrokerLicenseNumber: 'Ingrese su número de licencia de corredor',
    companyAddress: 'Dirección de empresa',
    inputCompanyAddress: 'Nombre de la dirección de empresa',
    agentType: 'Tipo de empresa',
    inputAgentType: 'Ingrese el tipo de Empresa',
    businessHours: 'Horario de atención',
    inputBusinessHours: 'Ingrese el Horario',
    inputWeb: 'Ingrese el link de su Web',
    inputSkype: 'Ingrese el link de su Skyper',
    inputFacebook: 'Ingrese el link de su Facebook',
    inputTwitter: 'Ingrese el link de su Twitter',
    inputInstagram: 'Ingrese el link de su Instagram',
    inputLinkedin: 'Ingrese el link de su Linkedin',

    consultationDetails: 'Más Información',
    broker: 'Encuenta un Profesional',
    brokerParticulars: 'Detalles de Profecional',

    brand: 'La marca',
    percent: 'Vídeo en proceso',


    PersonalIntroduction: 'Una introducción personal',
    inputPersonalIntroduction: 'Por favor complete su introducción personal',
    IDNumber: 'Cédula',
    inputIdNumber: ' Número de Cédula',
    aboutCompany: 'Descripción de la Empresa',
    photoCompany: 'Galería de fotos de la Empresa',

    pleaseEnterCompanyProfile: 'Perfil de la empresa',
    copySuccess: 'Replicar el éxito',
    email: 'Correo Electrónico',
    mobile: 'Celular',
    code: 'Código de verificación',
    inputEmailCode: 'Introduca el código de vedificación',
    completeInfor: "Por favor, complete su información personal",
    scanLogin: 'Código de barrido login',
    sendSuccess: 'Enviado con éxito',
    isStop: 'Activado/Desactivar',

    contactUs: 'Contáctenos',
    techniqueFeedback: 'Sugerencia',
    business: 'Ventas',
    WechatNumber: 'Wechat',
    emailVerify: 'Email',
    mobileVerify: 'Celular',
    verify: 'Verificaciones',
    importantTips: 'Importante: Es indispensable hacer la verificación de su número de celular y/o 	correo electrónico para la publicaciónes.',
    interestAdvertise: 'Estoy muy interesado en su anuncio, por favor contacte conmigo!',
    advertiseLink: 'Enlaces para publicidad',
    weChatScan: 'Wechat barrido para compartir',
    talent: "Trabaja con Nosotros",
    complain: 'Querella',

    retainedOwnership: 'Todos los derechos reservados',
    privacyPolicy2: 'Política de privacidad',
    userRegistrationAgreement2: 'Términos y condiciones',
    companyLogo: 'Logo',
    uploadLogo: 'Sube tu logo',
    contactCompany: 'Informaciones de Contactos',


    Know: 'OK',
    goHome: 'VOLVER',

    imgMessage: 'Formato soportado: JPEG, GIF, PNG.  Tamaño sugerido: 2048px x 450px.  Peso max.  2Mb',
    videoMessage: 'Formato soportado: MP4, WEBM, OGG...',



    shunxu1:'min. - max.',
    shunxu2:'min. - max',


    selectTime: 'Fecha',
    selectMoney: 'Orden de Precio',
    selectMoneyQJ: 'Rango de Precio',
    selectMoneyCar: 'seleccione Marca del coche',

    // inputZhuxiaoReson:'Ingresa Motivo de cancelación',


    zhuixiao: 'Cancelación de la cuenta',
    zhuxiaoReason: 'Especifica',
    zhuxiaoType: '¿Cuál es el motivo por el cual te darás de baja?',
    InputZhuxiaoReason: 'Ingresa Especifica',
    InputZhuxiaoType: 'Ingresa Especifica',

    
    //支付弹窗
    pay: {
        amountPayable: 'Monto a pagar',
        include: 'Incluido',
        taxRate: 'ITBMS',
        payNow: 'Pagar',

    },

    // 首页底部的弹窗
    dialog: {
        technicalSupport: 'SOPORTE TÉCNICO',

        ContactUs: 'Contáctenos',
        UserForum: 'Foro de Usuarios',
        AskForHelp: 'Solicite Ayuda',
        news: 'Noticias',
        Sign: 'Registro',
        works: 'Trabaja en Encuentra24',
        Glossary: 'Glosario de mejores prácticas',

        LegalClause: 'TÉRMINOS LEGALES',

        SafetyCenter: 'Centro de Seguridad',
        PrivacyPolicy: 'Política de Privacidad',
        TermsOfService: 'Condiciones del Servicio',
        expense: 'Tarifas',
        RefundPolicy: 'Política de Reembolsos',
        SafetyTips: 'Consejos de Seguridad',

    },



    // 需要翻译的增项
    editRelease: 'Editar Publicación',
    dateSort: 'Fecha',
    lastTime: 'Ultimo tiempo',
    farTime: 'Tiempo mas lejano',
    inforOrder: 'Mis Anuncios Posicionados',
    adOrder: 'Gestionar Pautas Publicitarias',
    vipOrder: 'Mis Suscripciones',
    order: {
        all: 'Todos',
        obligation: 'Por pagar',
        finish: 'Completado',
        orderNo: 'ID de la publicación',
        hasCancel: 'Cancelado',
        accountPayable: 'Total a pagar',
        cancelOrder: 'Cancelar pedido',
        toPay: 'Pagar',
        deleteOrder: 'Eliminar pedido',
        orderDetail: 'Detalles del pedido',
        orderToPay: 'Pedido por pagar',
        orderHasCancel: 'Pedido cancelado',
        orderHasFininsh: 'Pedido finalizado',
        youHasCancelOrder: 'Usted ya se cancelo el pedido',
        restTime: 'Tiempo restante',
        youHasPayOrder: 'El pedido ya esta pago, espera la aprobación',
        messageFee: 'Valor de anuncios',
        informationDuration: 'Su publicación es por',
        tian: 'días',

        locationHighlight: 'Resaltar la posición',
        locationRecommend: 'Posición recomendado',
        orderInfor: 'Información del pedido',
        orderTime: 'Fecha de pedido',
        payMoney: 'Total a pagar',
        payWay: 'Forma de pago',
        payTime: 'Selecione método de pago',
        adFee: 'Costo de anuncio',
        vipFee: 'Costo de VIP',
        applyAd: "Pauta Publicitaria",
        editAd: 'Editar posición de anuncios',
        adPosition: "Ubicación de la Pauta Publicitaria",
        adContent: "Publicación vinculada a la Pauta Publicitaria",
        adDuration: '¿Por cuantos días desea realizar la Pauta Publicitaria?',
        adLianjie: 'Enlaces externos',
        inputDuration: 'Ingresar tiempo a publicar',
        photo: 'Subir',
        aSheet: 'su Anuncio',
        toPay: 'Pagar',
        adIntroduce: 'Información de Anuncios',
        selectAdContent: 'Seleccione la publicación que desea vincular',
        pleaseSelectAdPosition: 'Seleccionar posición de anuncios',
        pleaseSelectAdContent: 'Seleccione el anuncio al cual quiere vincular la pauta', //Seleccionar contendos de anuncios
        pleaseSelectAdDuration: 'Ingrese por cuantos días desea realizar la Pauta Publicitaria',
        pleaseSelectAdLianjie: 'Por favor ingrese un enlace externo',

        unploadAd: 'Ingrese el arte de su anuncio',
        tipPhoto: 'Subir Arte (Cargue el arte de su Pauta Publicitaria, por favor ver que que las medidas sean correctas)'
    },
    myAd: 'Mis Pautas Publicitarias',
    myOrder: 'Mi pedidos',

    talentHire: 'Se busca talentos',
    postType: 'Vacantes',
    choosePostType: 'Por favor seleccione el tipo de post',
    resumeUpload: 'Adjuntar Hoja de Vida',
    yourResumeHasBeenSubmittedSuccessfully: '¡Su hoja de vida fue enviada con éxito!',
    thePlatformStaffWillContactYouAfterAdmission: 'Se le contactará pronto',
    talentHireTitle: 'Nuestra plataforma protege su información, coloque sus datos con confianza',
    jingweitishi: 'Nota: Al seleccionar esta opción su artículo pasará a estar en el Top de la lista',

    day: 'Dias',
    confirmDeleteCurrentOrder: 'eguro quiere eliminar el pedido?',
    applyTime: 'Fecha de aprobación',
    thePlatformGuaranteesYourInformation: 'Su informacion solo uso para RRHH',
    name: 'Nombre',
    inputName: 'Ingresa el nombre',
    residentialAddress: 'Dirección',
    inputResidentialAddress: 'Ingresa su dirección actual',
    showStyle: 'Forma de Presentar',
    positionTheLegislature: 'Posicionar primer lugar',
    positionToHighlight: 'Resaltar su Posición',
    ruleDescription: 'Reglas de posionar',
    ruleDescriptionContent: 'La posición es prominente, solo cambie el color de fondo de la información publicada y el otro contenido permanece sin cambios La posición hacia adelante se refiere a la recomendación popular, la pantalla frontal pagada y la marca de asterisco',
    endTime: 'Fecha de caducidad',
    pleaseUploadResume: 'Enviar currículum',

    // 需要翻译的
    view: 'ver',
    open: 'Publicaciones',
    money: 'Monto',
    submitOrder: 'Enviar Pedido',
    payOrder: 'Pagar',
    finishOrder: 'Completado',
    positionToHighlightIntroduce: 'Descripción de posición destacada',
    positionTheLegislatureIntroduce: 'Instrucciones de posición push forward',
    uploadText: 'Subir',
    vipName: 'Detalles',
    clickUpload: 'Subir Archivos',
    atMostPhoto: 'Sube solo una Imagen en formato JPG ó PNG',
    onlyUploadPDFAndImg: 'Solo acepta archivos en formatos PDF y JPG',
    uploadPdfOrImage: 'Por favor, sube un PDF o una imagen',
    systemInformation: 'Información del sistema',
    clickForDetails: 'Click para detalles',
    chooseAreaCode: 'Por favor seleccione el código de área',

  
    vipTiao:'Disponibles',
    vipNumber:'Publicaciones'

}